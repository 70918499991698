import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseurl } from "../../url";
import Navbar from "./FacultyNavbar";
import "./att.css";

const AttendanceForm = () => {
  const [semester, setSemester] = useState("");
  const [course, setCourse] = useState("");
  const [subject, setSubject] = useState("");
  const [hour, setHour] = useState(""); // For single hour
  const [multipleHours, setMultipleHours] = useState([]); // For multiple hours
  const [allowMultipleHours, setAllowMultipleHours] = useState(false); // Toggle for multiple hours
  const [date, setDate] = useState("");
  const [division, setDivision] = useState(""); // New state for division
  const [students, setStudents] = useState([]);
  const [courses, setCourses] = useState([]);
  const [semesters, setSemesters] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [teachername, setTeacherName] = useState("");
  const [loading, setLoading] = useState(false);
  const [markAllPresent, setMarkAllPresent] = useState(false);
  const [alreadyMarked, setAlreadyMarked] = useState(false);
  const [markedSubject, setMarkedSubject] = useState("");
  const [existingAttendance, setExistingAttendance] = useState([]);
  const [noStudentsMessage, setNoStudentsMessage] = useState(false);
  const [lab, setLab] = useState(""); // State for lab selection
  const [markedDates, setMarkedDates] = useState([]);
  const [showMarkedDates, setShowMarkedDates] = useState(false);
  const [studentsFetched, setStudentsFetched] = useState(false); 
  

  useEffect(() => {
    const fetchCoursesAndSemesters = async () => {
      const email = localStorage.getItem("email");
      try {
        const response = await axios.post(`${baseurl}/api/data/attendance`, {
          email,
        });
        const { subjects, semesters, branches, teachername } = response.data;
        setCourses(branches || []);
        setSemesters(semesters || []);
        setSubjects(subjects || []);
        setTeacherName(teachername);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchCoursesAndSemesters();

    const currentDate = new Date().toISOString().split("T")[0];
    setDate(currentDate);
  }, []);
  const fetchMarkedDates = async () => {
    try {
      const response = await axios.post(`${baseurl}/api/attendance/dates`, {
        course,
        semester,
        division,
        subject,
        lab, // Include lab if selected
      });
      setMarkedDates(response.data);
    } catch (error) {
      console.error("Error fetching marked attendance dates:", error);
    }
  };

  // Call fetchMarkedDates after selecting all required fields including lab if it's needed
  useEffect(() => {
    if (
      course &&
      semester &&
      division &&
      subject &&
      (!shouldShowLabField() || lab)
    ) {
      fetchMarkedDates();
    }
  }, [course, semester, division, subject, lab]);

  const toggleMarkedDatesVisibility = () => {
    setShowMarkedDates(!showMarkedDates);
  };
 

  const fetchStudents = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${baseurl}/api/attendance/fetch`, {
        course,
        semester,
        division, // Include division in the request
        lab, // Include lab in the request
      });

      const sortedStudents = response.data.sort((a, b) =>
        a.RollNo.localeCompare(b.RollNo, undefined, { numeric: true })
      );
      setLoading(false);
      setStudentsFetched(true);
      return sortedStudents;
    } catch (error) {
      setLoading(false);
      console.error("Error fetching students:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    try {
      const hoursToCheck = allowMultipleHours ? multipleHours : [hour];
      for (const selectedHour of hoursToCheck) {
        const checkResponse = await axios.post(
          `${baseurl}/api/attendance/check`,
          {
            date,
            hour: selectedHour,
            teachername,
            subject,
            course,
            division, 
            lab,
          }
        );
      
      if (checkResponse.data.isMarked) {
        if (checkResponse.data.type === "sameSubject") {
          const existingAttendanceResponse = await axios.post(
            `${baseurl}/api/attendance/existing`,
            {
              date,
              hour,
              teachername,
              subject,
              course,
              division, // Include division in existing attendance
              lab,
            }
          );

          setExistingAttendance(existingAttendanceResponse.data);
          setStudents(
            existingAttendanceResponse.data.map((record) => ({
              _id: record.student._id,
              name: record.student.name,
              RollNo: record.student.RollNo,
              attendance: record.status,
            }))
          );
          setNoStudentsMessage(false);
        } else if (
          checkResponse.data.type === "differentSubject" ||
          checkResponse.data.type === "differentTeacher"
        ) {
          setAlreadyMarked(true);
          alert(checkResponse.data.message);
          setExistingAttendance([]);
          setStudents([]);
        }
      } else {
        const fetchedStudents = await fetchStudents();
        if (fetchedStudents.length === 0) {
          setNoStudentsMessage(true);
        } else {
          setNoStudentsMessage(false);
        }
        setStudents(fetchedStudents);
        setAlreadyMarked(false);
        setExistingAttendance([]);
      }
    }
    } catch (error) {
      console.error("Error checking attendance or fetching students:", error);
    } finally {
      setLoading(false);
    }
    };

  const submitAttendance = async () => {
    setLoading(true);
    try {
      const hoursToSubmit = allowMultipleHours ? multipleHours : [hour];
      for (const selectedHour of hoursToSubmit) {
        const updatedStudents = students.map((student) => ({
          studentId: student._id,
          date,
          subject,
          hour: selectedHour,
          teachername,
          attendance: student.attendance,
          division, 
          lab,
        }));
  
        await Promise.all(
          updatedStudents.map((student) =>
            axios.post(`${baseurl}/api/attendance`, student)
          )
        );
      }
  

      alert("Attendance updated successfully!");
      setCourse("");
      setSemester("");
      setSubject("");
      setHour("");
      setDivision(""); // Reset division selection
      setLab("");
      setAllowMultipleHours("");
      setMarkAllPresent("");
      setStudents([]);
    } catch (error) {
      console.error("Error marking attendance:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleAttendanceChange = (studentId, isPresent) => {
    setStudents((prevStudents) =>
      prevStudents.map((student) =>
        student._id === studentId
          ? { ...student, attendance: isPresent ? "Present" : "Absent" }
          : student
      )
    );
  };

  const handleMarkAllPresentChange = (e) => {
    const isChecked = e.target.checked;
    setMarkAllPresent(isChecked);
    setStudents((prevStudents) =>
      prevStudents.map((student) => ({
        ...student,
        attendance: isChecked ? "Present" : "Absent",
      }))
    );
  };

  const shouldShowLabField = () => {
    const lowerCaseSubject = subject.toLowerCase();
    return (
      lowerCaseSubject.includes("lab") ||
      lowerCaseSubject.includes("project") ||
      lowerCaseSubject.includes("seminar")
    );
  };

  return (
    <div>
      <Navbar />
      <div className="attendance-form">
        <form onSubmit={handleSubmit}>
          <label>
            Course:
            <select value={course} onChange={(e) => setCourse(e.target.value)}>
              <option value="">Select Course</option>
              {courses.map((course) => (
                <option key={course} value={course}>
                  {course}
                </option>
              ))}
            </select>
          </label>
          <label>
            Semester:
            <select
              value={semester}
              onChange={(e) => setSemester(e.target.value)}
            >
              <option value="">Select Semester</option>
              {semesters.map((semester) => (
                <option key={semester} value={semester}>
                  {semester}
                </option>
              ))}
            </select>
          </label>
          <label>
            Division:
            <select
              value={division}
              onChange={(e) => setDivision(e.target.value)}
            >
              <option value="">Select Division</option>
              <option value="A">A</option>
              <option value="B">B</option>
              <option value="C">C</option>
            </select>
          </label>
          <label>
            Subject:
            <select
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            >
              <option value="">Select Subject</option>
              {subjects.map((subject) => (
                <option key={subject} value={subject}>
                  {subject}
                </option>
              ))}
            </select>
          </label>
          {shouldShowLabField() && (
            <label>
              Lab:
              <select value={lab} onChange={(e) => setLab(e.target.value)}>
                <option value="">Select Lab</option>
                <option value="Lab 1">Lab 1</option>
                <option value="Lab 2">Lab 2</option>
              </select>
            </label>
          )}
      
      <div className="allow-multiple-hours-container">
  <label className="allow-multiple-hours">
    Allow Multiple Hours:
  </label>
  <label className="allow-multiple">
    <input
      type="checkbox"
      checked={allowMultipleHours}
      onChange={() => setAllowMultipleHours(!allowMultipleHours)}
    />
  </label>
</div>


{allowMultipleHours ? (
  <label>
    Select Hours:
    <select
      multiple
      value={multipleHours}
      onChange={(e) =>
        setMultipleHours(Array.from(e.target.selectedOptions, option => option.value))
      }
    >
      <option value="1"> 1</option>
      <option value="2">2</option>
      <option value="3"> 3</option>
      <option value="4"> 4</option>
      <option value="5"> 5</option>
      <option value="6"> 6</option>
    </select>
  </label>
) : (
  <label>
    Hour:
    <select value={hour} onChange={(e) => setHour(e.target.value)}>
      <option value="">Select Hour</option>
      <option value="1"> 1</option>
      <option value="2"> 2</option>
      <option value="3"> 3</option>
      <option value="4"> 4</option>
      <option value="5"> 5</option>
      <option value="6"> 6</option>
    </select>
  </label>
)}

          <label>
            Date:
            <input
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </label>

          {markedDates.length > 0 && (
            <div>
              {/* Button to toggle the visibility */}
              <button onClick={toggleMarkedDatesVisibility}>
                {showMarkedDates ? "Hide Marked Dates" : "Show Marked Dates"}
              </button>
            </div>
          )}
          {showMarkedDates && (
            <div className="marked-dates">
              <h4>Marked Attendance Dates and Hours:</h4>
              <table>
                <thead>
                  <tr>
                    <th>Date </th>
                    <th>Hour</th>
                  </tr>
                </thead>
                <tbody>
                  {markedDates
                    .slice() // Create a shallow copy to avoid mutating the original array
                    .sort((a, b) => {
                      const dateA = new Date(
                        a.date.split("-").reverse().join("-")
                      ); // Convert 'dd-mm-yy' to 'yy-mm-dd'
                      const dateB = new Date(
                        b.date.split("-").reverse().join("-")
                      );
                      return dateA - dateB; // Sort by date
                    })
                    .map((attendance, index) => (
                      <tr key={index}>
                        <td>{attendance.date}</td>
                        <td>{attendance.hour} </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          )}

          <button type="submit">Fetch Students</button>
        </form>

        {loading && <p>Loading...</p>}
        {noStudentsMessage && (
          <p>No students found for the selected combination.</p>
        )}

        { alreadyMarked && existingAttendance.length > 0 && (
          <div>
            <p>
              Attendance for {hour} hour on {date} is already marked for subject{" "}
              {markedSubject} by you.
            </p>
            <table className="att">
              <thead>
                <tr>
                  <th>Roll No</th>
                  <th>Student Name</th>
                  <th>Present</th>
                </tr>
              </thead>
              <tbody>
                {students.map((student) => (
                  <tr key={student._id}>
                    <td>{student.RollNo}</td>
                    <td>{student.name}</td>
                    <td>
                      {student.attendance === "Present" ? "Present" : "Absent"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {!alreadyMarked && students.length > 0 && (
          <div>
            <h2>Mark Attendance for {subject}</h2>
            <label>
              Mark All Present:
              <input
                type="checkbox"
                checked={markAllPresent}
                onChange={handleMarkAllPresentChange}
              />
            </label>
            <table className="att">
              <thead>
                <tr>
                  <th>Roll No</th>
                  <th>Student Name</th>
                  <th>Present</th>
                  <th>Absent</th>
                </tr>
              </thead>
              <tbody>
                {students.map((student) => (
                  <tr key={student._id}>
                    <td>{student.RollNo}</td>
                    <td>{student.name}</td>
                    <td>
                      <input
                        type="radio"
                        name={`attendance-${student._id}`}
                        checked={student.attendance === "Present"}
                        onChange={() =>
                          handleAttendanceChange(student._id, true)
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="radio"
                        name={`attendance-${student._id}`}
                        checked={student.attendance === "Absent"}
                        onChange={() =>
                          handleAttendanceChange(student._id, false)
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <button onClick={submitAttendance}>Submit Attendance</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AttendanceForm;
